import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import * as Sentry from "@sentry/vue";

Sentry.init({
  Vue,
  dsn: "https://c6809975644f4747b89a992269ab405d@o482137.ingest.sentry.io/4505356680626176",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});


import FormBuilder from "lucien-form-builder";
Vue.use(FormBuilder);

import VueTippy from "vue-tippy";
Vue.use(VueTippy);

import { Simplert } from "vue2-simplert-plugin";
import "vue2-simplert-plugin/dist/vue2-simplert-plugin.min.css";
Vue.use(Simplert);

import "./assets/styles/index.scss";
import { Init } from "./libs/init.js";


Vue.config.productionTip = false;

var init = Init();
init.then(function (init) {
  new Vue({
    data: {
      configOp: {
        datas: init,
      },
    },
    router,
    render: (h) => h(App),
  }).$mount("#app");
});
