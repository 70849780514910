<template>
  <div class="w-full md:h-[72px] p-4 bg-[#0B1D33] foot">
    <div class="h-auto md:h-10 text-center text-white">
      <ul
        class="navlink md:flex justify-center items-start text-xs font-semibold"
      >
        <li v-if="datas.mentions">
          <a :href="datas.mentions" target="_blank">Mentions légales</a>
        </li>

        <li>
          <a :href="datas.politique" target="_blank">Confidentialité</a>
        </li>

        <!--<li v-if="datas.cookies">
            <a :href="datas.cookies"  target="_blank" >Cookies</a>
          </li>-->

        <li v-if="datas.externalLink && datas.externalLabel">
          <a :href="datas.externalLink" target="_blank">{{
            datas.externalLabel
          }}</a>
        </li>
      </ul>
      <div class="text-sm my-1">
        <a href="https://www.profilplus.fr/" target="_blank"
          >www.profilplus.fr</a
        >
        <span class="inline-blockck m-4 text-xl"
          ><a href="https://www.facebook.com/Profilplus" target="_blank"
            ><i class="fa-brands fa-square-facebook"></i></a
        ></span>
        <span class="inline-blockck mr-4 text-xl"
          ><a
            href="https://www.linkedin.com/company/profil-plus/?viewAsMember=true"
            target="_blank"
            ><i class="fa-brands fa-linkedin"></i></a
        ></span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Footer",
  data: function () {
    return {
      datas: this.$root.configOp.datas,
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>

<style scoped>
.navlink li:not(:last-child):after {
  @apply text-white mx-4 hidden md:inline-block;
  content: "|";
  color: white !important;
}

.navlink li {
  @apply py-2 md:py-0;
}

.navlink li a {
  @apply cursor-pointer font-light text-lg;
}

.foot a:hover {
  color: white !important;
}
</style>
