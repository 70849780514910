<template>
  <div class="w-full md:h-[72px] p-4 bg-[#E8E8E8]">
    <div class="h-auto mt-2 md:h-10 text-center text-black">
      <ul
        class="navlink md:flex justify-center items-start text-xs font-semibold"
      >
        <li v-if="datas.mentions">
          <a :href="datas.mentions">Mentions légales</a>
        </li>

        <li>
          <a :href="datas.politique" target="_blank"
            >Politique de gestion de données utilisateurs</a
          >
        </li>

        <li v-if="datas.cookies">
          <a :href="datas.cookies" target="_blank">Paramètrez les cookies</a>
        </li>

        <li v-if="datas.externalLink && datas.externalLabel">
          <a :href="datas.externalLink" target="_blank">{{
            datas.externalLabel
          }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "Footer",
  data: function () {
    return {
      datas: this.$root.configOp.datas,
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>

<style scoped>
.navlink li:not(:last-child):after {
  @apply text-black mx-4 hidden md:inline-block;
  content: "|";
  color: black !important;
}

.navlink li {
  @apply py-2 md:py-0 font-semibold;

}

.navlink li a {
  @apply cursor-pointer font-semibold;

}

.navlink li a:hover {
  color: #ff6600 !important;
}


</style>