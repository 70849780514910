<template>
  <div class="w-full md:py-2 md:min-h-[130px]" id="menuContainerDiv">
    <div
      class="relative rupture m-auto flex justify-end middle:justify-between"
    >
      <button
        class="w-10 text-sm font-bold text-black absolute left-4 sm:left-8 top-5 sm:top-6 cursor-pointer middle:hidden"
        @click="toggleValue"
      >
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span>MENU</span>
      </button>

      <router-link :to="`${datas.local_link || '/'}`"
        ><img class="w-[220px] sm:w-[250px] md:w-[280px]" :src="datas.logo"
      /></router-link>

      <ul
        class="navlinks text-black !font-bold uppercase hidden middle:flex justify-center items-start pt-8 text-[15px]"
      >
        <li><router-link to="/old">offres terminées</router-link></li>
        <li><router-link to="/faq">faq</router-link></li>
        <li><router-link to="/suivi">suivi</router-link></li>
        <li><router-link to="/contact">contactez-nous</router-link></li>
      </ul>
    </div>
    <div class="py-6 border-t-2 middle:hidden" v-if="toggle">
      <ul class="text-center uppercase font-[14px] leading-10 text-[#919191]">
        <li @click="toggleValue"><router-link to="/old">offres terminées</router-link></li>
        <li @click="toggleValue"><router-link to="/faq">faq</router-link></li>
        <li @click="toggleValue"><router-link to="/suivi">suivi</router-link></li>
        <li @click="toggleValue"><router-link to="/contact">contactez-nous</router-link></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data: function () {
    return {
      toggle : false,
      datas: this.$root.configOp.datas,
    };
  },
  methods : {
    toggleValue() {
      this.toggle = !this.toggle;
    }
  }
};
</script>

<style scoped>

@font-face {
  font-family: 'Open SansLight';
  src: url('@/assets/fonts/OpenSans-Light.eot');
  src: url('@/assets/fonts/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
      url('@/assets/fonts/OpenSans-Light.woff2') format('woff2'),
      url('@/assets/fonts/OpenSans-Light.woff') format('woff'),
      url('@/assets/fonts/OpenSans-Light.ttf') format('truetype'),
      url('@/assets/fonts/OpenSans-Light.svg#OpenSans-Light') format('svg');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

#menuContainerDiv {
  background-image: url(@/assets/images/allopneus/ombre.png);
  background-position: 0 100%;
  background-repeat: repeat-x;
}

.navlinks li:not(:last-child):after {
  @apply mx-1 xl:mx-4;
  color: #ff6600 !important;
  content: "|";
  font-weight: 300;
}

.navlinks li a:hover {
  color: #ff6600 !important;
}

.navlinks li a {
@apply cursor-pointer font-light;
font-family: 'Open SansLight' !important;
}

.icon-bar {
  @apply h-[3px] bg-primary-bg-color w-full block my-[5px];
}
</style>
