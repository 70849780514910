<template>
  <div v-if="datas && currentRouteName != 'operation'">
    <Allopneus v-if="datas.host === 'allopneu.promo.dev'" />
    <Profilplus v-if="datas.host === 'promoprofilplus.fr'" />
    <Classic v-else />
  </div>
</template>
<script>
import Allopneus from "@/components/allopneus/Header";
import Profilplus from "@/components/profilplus/Header";
import Classic from "@/components/classic/Header";

export default {
  name: "App",
  data: function () {
    return {
      datas: this.$root.configOp.datas,
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  components: {
    Allopneus,
    Profilplus,
    Classic,
  },
};
</script>
