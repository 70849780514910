<template>
  <div class="relative w-full md:pb-2 md:min-h-[145px] heads">
    
    <div
      class="absolute bottom-0 w-full bg-[#0B1D33] h-[50px] text-white uppercase hidden middle:flex items-center !font-bold text-[16px]"
    >
      <ul
        class="z-20  font-bold rupture navlinks text-white uppercase middle:flex justify-end items-center m-auto w-full italic"
      >
        <li class="font-extrabold">
          <router-link :to="`${datas.local_link}/`">ACCUEIL</router-link>
        </li>
        <li><router-link :to="`${datas.local_link}/old`">offres terminées</router-link></li>
        <li><router-link :to="`${datas.local_link}/suivi`">suivi</router-link></li>
        <li><router-link :to="`${datas.local_link}/contact`">contactez-nous</router-link></li>
      </ul>
    </div>

    <div class="relative rupture m-auto flex middle:justify-between z-10">
      <button
      @click="toggleValue"
        class="w-12 bg-[#0B1D33] h-12 px-2 py-0 rounded-md font-bold text-white absolute right-4 sm:right-8 top-5 sm:top-6 cursor-pointer middle:hidden"
      >
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>

      <router-link :to="`${datas.local_link || '/'}`"
        ><img class="w-[200px] sm:w-[225px]" :src="datas.logo"
      /></router-link>
    </div>

    <div class="py-2 middle:hidden" v-if="toggle">
      <ul class="text-center uppercase font-[15px] leading-10 text-white bg-[#0b1d32] py-4 rounded-md mx-4">
        <li @click="toggleValue" class="font-extrabold">
          <router-link :to="`${datas.local_link}/`">ACCUEIL</router-link>
        </li>
        <li @click="toggleValue" ><router-link :to="`${datas.local_link}/old`">offres terminées</router-link></li>
        <li @click="toggleValue"><router-link :to="`${datas.local_link}/suivi`">suivi</router-link></li>
        <li @click="toggleValue"><router-link :to="`${datas.local_link}/contact`">contactez-nous</router-link></li>
      </ul>
    </div>
  </div>
</template>

<script scoped>
export default {
  name: "Header",
  data: function () {
    return {
      toggle: false,
      datas: this.$root.configOp.datas,
    };
  },
  methods: {
    toggleValue() {
      this.toggle = !this.toggle;
    },
  },
};
</script>

<style>
.navlinks li:not(:last-child):after {
  @apply text-white px-4 xl:px-6;
  color:white !important;
  content: "|";
}

.navlinks li a:hover {
  color: white !important;
}

.heads ul li a {
  @apply cursor-pointer font-bold italic;

}

.icon-bar {
  @apply h-[3px] bg-white w-full block my-[5px];
}
</style>
