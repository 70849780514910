import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/404",
      alias: "/:_id/404",
      name: "404",
      component: () => import("./views/404.vue"),
    },
    {
      path: "/faq",
      alias: "/:_id/faq",
      name: "faq",
      component: () => import("./views/Faq.vue"),
    },
    {
      path: "/old",
      alias: "/:_id/old",
      name: "old",
      component: () => import("./views/Old.vue"),
    },
    {
      path: "/operation/:opid",
      alias: "/:_id/operation/:opid",
      name: "operation",
      component: () => import("./views/Operation.vue"),
    },
    {
      path: "/form/:opid",
      alias: "/:_id/form/:opid",
      name: "form",
      component: () => import("./views/Form.vue"),
    },
    {
      path: "/contact",
      alias: "/:_id/contact",
      name: "contact",
      component: () => import("./views/Contact.vue"),
    },
    {
      path: "/contactdone",
      alias: "/:_id/contactdone",
      name: "contactdone",
      component: () => import("./views/ContactDone.vue"),
    },
    {
      path: "/status/:_subid/:_uid",
      alias: "/:_id/status/:_subid/:_uid",
      name: "status",
      component: () => import("./views/Follow.vue"),
    },
    {
      path: "/operation/informations/suivi-conso",
      name: "suivi2",
      component: () => import("./views/Suivi.vue"),
    },
    {
      path: "/suivi",
      alias: "/:_id/suivi",
      name: "suivi",
      component: () => import("./views/Suivi.vue"),
    },
    {
      path: "/operation/informations/suivi-conso",
      name: "suivi2",
      component: () => import("./views/Suivi.vue"),
    },
    {
      path: "/",
      name: "home",
      alias: '/:_id',
      component: () => import("./views/Home.vue"),
    },
  ],
});

export default router;
