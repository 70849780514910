<template>
  <div id="app">
    <headerElem />
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <FooterElem />
    <span v-if="style" v-html="style"></span>
  </div>
</template>

<script>
import HeaderElem from "@/components/Header";
import FooterElem from "@/components/Footer";

export default {
  name: "App",
  data: function () {
    return {
      datas: this.$root.configOp.datas,
      style: undefined,
    };
  },
  mounted() {
    if (!this.datas) {
      if (this.$route.path != "/404") {
        this.$router.push(`/404`);
      }
     
    }
    if (this.datas && this.datas.custom && this.datas.custom.cssRules) {
      this.style = `<style lang="scss">${this.datas.custom.cssRules}</style>`;
    }
  },
  components: {
    HeaderElem,
    FooterElem,
  },
};
</script>
